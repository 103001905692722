import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const serviceSchemaMenuRegistry = {
    console_serviceSchema: {
        label: "Service Schema",
        link: PAGE_URL.SERVICE_SCHEMA_PAGE,
        key: PAGE_URL.SERVICE_SCHEMA_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default serviceSchemaMenuRegistry;
