import {
  UilSetting,
  UilUsersAlt,
  UilNewspaper,
  UilCreateDashboard
} from '@iconscout/react-unicons';
import React from "react";
import menuRegistry from "./MenuLoader";
const menuConfig = [
  {
    label: 'Users Management',
    key: 'Users Management',
    icon: <UilUsersAlt />,
    children: [
      {
        ...menuRegistry['core_user'],
        label: "All Users"
      },
      {
        ...menuRegistry['core_userAuthority'],
        label: "Roles",
      },
      {
        ...menuRegistry['core_permissionGroup'],
        label: "Permission Group",
      },
      {
        ...menuRegistry['core_permission'],
        label: "Permissions",
      }
    ],
  },
  {
    label: 'Application',
    key: 'Application',
    icon: <UilCreateDashboard />,
    children: [
      {
        ...menuRegistry['core_apiKeyCredentials'],
        label: "Key Credentials",
      },
      {
        ...menuRegistry['core_apiKeyPermission'],
        label: "Key Permissions",
      }
    ],
  },
  {
    label: 'Config System',
    key: 'Config System',
    icon: <UilSetting />,
    children: [
      {
        ...menuRegistry['core_configSystem'],
        label: "Core Config"
      },
      {
        ...menuRegistry['personalize_pnlConfigSystem'],
        label: "Personalize Config"
      },
      {
        ...menuRegistry['company_configSystem'],
        label: "Company Config"
      },
      {
        ...menuRegistry['crm_configSystem'],
        label: "Crm Config"
      }
    ],
  },
  {
    label: 'Generate',
    key: 'Generate',
    icon: <UilNewspaper />,
    children: [
      {
        ...menuRegistry['console_serviceRegister'],
        label: "Service Register"
      },
      {
        ...menuRegistry['console_serviceSchema'],
        label: "Service Schema"
      }
    ],
  }
];

export default menuConfig;
