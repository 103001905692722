import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const apiKeyPermissionMenuRegistry = {
    core_apiKeyPermission: {
        label: "Api Key Permission",
        link: PAGE_URL.API_KEY_PERMISSION_PAGE,
        key: PAGE_URL.API_KEY_PERMISSION_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default apiKeyPermissionMenuRegistry;
