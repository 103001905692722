import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const apiKeyPermissionMappingMenuRegistry = {
    core_apiKeyPermissionMapping: {
        label: "Api Key Permission Mapping",
        link: PAGE_URL.API_KEY_PERMISSION_MAPPING_PAGE,
        key: PAGE_URL.API_KEY_PERMISSION_MAPPING_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default apiKeyPermissionMappingMenuRegistry;
