import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const apiKeyCredentialsMenuRegistry = {
    core_apiKeyCredentials: {
        label: "Api Key Credentials",
        link: PAGE_URL.API_KEY_CREDENTIALS_PAGE,
        key: PAGE_URL.API_KEY_CREDENTIALS_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default apiKeyCredentialsMenuRegistry;
