import Loading from "@/components/Loading";
import AdminLayout from "@/layout/AdminLayout";
import React from "react";
import {PAGE_URL} from "../services/constant";
const ApiKeyCredentials = React.lazy(() =>
    import('../ApiKeyCredentials')
);

const ApiKeyCredentialsDetail = React.lazy(() =>
    import('../ApiKeyCredentialsDetail')
);

const apiKeyCredentialsRoutesRegistry = [
    {
        path: PAGE_URL.API_KEY_CREDENTIALS_PAGE,
        element: <ApiKeyCredentials />,
        loading: <Loading />,
        layout: AdminLayout,
    },
    {
        path: `${PAGE_URL.API_KEY_CREDENTIALS_PAGE}/:id`,
        element: <ApiKeyCredentialsDetail />,
        loading: <Loading />,
        layout: AdminLayout,
    },
];

export default apiKeyCredentialsRoutesRegistry;
