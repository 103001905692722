const Storage = {
  PREFIX: 'sim',
  ACCESS_TOKEN: 'access-token',
  REFRESH_TOKEN: 'refresh-token',
  COLLAPSED: 'collapsed',
  MENU_OPEN_KEYS: 'menu-open-keys',
  USER_INFO: "user-info"
};

export default Storage;
