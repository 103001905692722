import {PAGE_URL, PERMISSION_KEY} from "../services/constant";
const serviceRegisterMenuRegistry = {
    console_serviceRegister: {
        label: "Service Register",
        link: PAGE_URL.SERVICE_REGISTER_PAGE,
        key: PAGE_URL.SERVICE_REGISTER_PAGE,
        resourceKey: PERMISSION_KEY
    }
}

export default serviceRegisterMenuRegistry;
